
import {
  Component, Vue, Prop, Emit,
} from "vue-property-decorator";
import { Action } from "vuex-class";
import { Team } from "@/model/team.model";
import { getTeamsModuleNamespace } from "@/store/store.utils";
import { HoldTeamParams } from "@/store/types";
import { dismissAction, logAndExtractMessage } from "@/utils/utils";

@Component({})
export default class PutTeamOnHoldDialog extends Vue {
  open = false;

  loading = false;

  minutes = "5";

  seconds = "0";

  @Prop()
  pTeam!: Team;

  @Action("holdTeam", getTeamsModuleNamespace())
  holdTeam!: (params: HoldTeamParams) => Promise<void>

  @Emit("onUpdate")
  emitOnUpdate() {
    return true;
  }

  get disableConfirm() {
    return this.isTemporalCombinationValid(this.minutes, this.seconds) !== true
        || this.isTemporalCombinationValid(this.seconds, this.minutes) !== true;
  }

  isTemporalCombinationValid(value: string, otherValue: string) {
    // The value can be 0, and it is important that the other value is not 0
    // For example, 0min 5sec and 5min 0sec is valid, while 0min 0sec is not
    return (
      value
      && (
        (+value === 0 && +otherValue > 0)
        || (+otherValue === 0 && +value > 0)
        || (+otherValue > 0 && +value > 0)
      )
      && +value <= 60
    )
    || "The value can be between 0 and 60";
  }

  async sendTeamOnHold() {
    this.loading = true;

    try {
      await this.holdTeam({ team: this.pTeam, minutes: +this.minutes, seconds: +this.seconds });
      this.emitOnUpdate();
      this.closeDialog();
    } catch (e) {
      Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
    }
    this.loading = false;
  }

  closeDialog() {
    this.open = false;
  }

  openDialog() {
    this.open = true;
    this.minutes = "5";
    this.seconds = "0";
  }
}
